
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { get } from '@/services/http';
import ChatWithUs from '@/components/ChatWithUs.vue';

@Component({
  components: {
    ChatWithUs,
  },
})
export default class DataPermissionNotice extends Vue {
  @Prop({default: false})
  public hasFullData!: boolean;
  @Prop({default: ''})
  public shopifyName!: string;
  public skipNotice: string = 'false';

  public created() {
    this.skipNotice = localStorage.getItem('skipNotice') || 'false';
  }

  public dismiss() {
    localStorage.setItem('skipNotice', 'true');
    this.skipNotice = 'true';
  }
}
